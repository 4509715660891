import React, { Component, Fragment, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-input-2/lib/style.css';
import './Content/themes/css/ansysoft.css';
import AssetTransferReportPDF from './components/Authenticate/ReportComponents/AssetTransferReportPDF';
const GeneralLayout = lazy(() => import('./components/Layout/GeneralLayout'));
const PublicLayout = lazy(() => import('./components/Layout/PublicLayout'));
const MainLayout = lazy(() => import('../src/components/Layout/Common/MainLayout'));
const ProtectedLayout = lazy(() => import('./components/Layout/ProtectedLayout'));
export default class App extends Component {
    static displayName = App.name;
    render() {
        return (
            <Fragment>
                <Suspense fallback={<div>Loading...</div>}>
                    <MainLayout>
                        <Switch>
                            <Route exact path='/app/*' component={ProtectedLayout} />
                            <Route exact path='/general/*' component={GeneralLayout} />
                            <Route exact path='/report' component={AssetTransferReportPDF} />
                            <Route path='/' component={PublicLayout} />
                        </Switch>
                    </MainLayout>
                </Suspense>
            </Fragment>
        );
    }
}
