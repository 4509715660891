import React, { forwardRef, useEffect, useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import { useAuth } from '../../Layout/Context/auth';
import baseConstant from '../../Layout/Common/baseConstant';
import CommonFunctions from '../../Layout/Common/CommonFunctions';
import apiIntegrationFunctions from '../../Layout/Common/IntegrationFunctions';
import dropDownListAction from '../../Layout/Common/dropDownListAction';

const AssetTransferReportPDF = ({ list, staffId, branchId, locationList }) => {

    const { authTokens } = useAuth();
    const { selectedAppBranchId } = useAuth();
    const [reportDetails, setReportDetails] = useState({});
    const [locationToList, setLocationToList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        filterAssetTransferReport()
    }, [staffId]);

    console.log(branchId);

    // //getLocationToList();
    // const getLocationToList = () => {
    //     CommonFunctions.getDropDownList(-1, dropDownListAction.ACT_BRANCH_LIST, function (response) {
    //         setLocationToList(response);
    //     });
    // }

    const filterAssetTransferReport = () => {
        if (CommonFunctions.bIsTableIdValued(branchId)) {
            var apiParameters = baseConstant.apiParametersWithoutLogin;
            if (authTokens) {
                apiParameters = baseConstant.apiParameters;
            }
            var raw = JSON.stringify({
                searchNumParam1: parseInt(branchId),
                searchNumParam2: parseInt(staffId),
                apiParameters: apiParameters
            });
            setLoading(true);
            CommonFunctions.ajaxCall(apiIntegrationFunctions.filterAssetTransferReport, raw, authTokens, 'POST', function (result, successFlag) {
                if (successFlag === true) {
                    if (result != null) {
                        if (result.lastCallStatus !== undefined) {
                            if (result.lastCallStatus == true) {
                                setLoading(false)
                                setReportDetails(result.lastCallData[result.lastCallData.length - 1]);
                            }
                            else {
                                setLoading(false)
                                toast.error(result.lastCallMessage);
                            }
                        }
                        else {
                            setLoading(false)
                            toast.error(JSON.stringify(result));
                        }
                    }
                    else {
                        setLoading(false)
                        toast.error(baseConstant.error_message);
                    }
                }
                else {
                    setLoading(false)
                    toast.error(JSON.stringify(result));
                }
            })
        } else {
            setReportDetails({});
        }
    }

    // console.log(reportDetails);

    return (
        <>
            {
                // loading === true &&
                <div className="transfer_form_container" id='content-to-download'>
                    <div className="transfer_form_header">
                        <p className="heading">Asset Transfer Form</p>
                    </div>
                    <div className="transfer_form_main">
                        <div className="transfer_form_section1">
                            <div className="brand_img1">
                                <img src={require("../../../Content/themes/images/site/narula_infra_logo.png")} width={90} height={70} />
                            </div>
                            <div className="org_desc">
                                <p className="org_title" >Narula Infrastructure Pvt. Ltd.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                <p className="org_details"> {reportDetails?.branch_address} </p>
                                {/* <p className="org_details">CIN No. : U70101WB2005 PTC 101157</p> */}
                                <p className="org_details">Phone : {reportDetails?.phone_no}</p>
                                <p className="org_details">E-mail : {reportDetails?.email} </p>
                            </div>
                            <div className="brand_img2">
                                <img src={require("../../../Content/themes/images/site/Narula-logo.png")} width={120} height={100} />
                            </div>
                        </div>

                        {/* <!-- transfer form main section2 --> */}
                        <div className="transfer_form_section2">
                            <div className="transfer_form_section2_left">
                                <ol>
                                    <li>
                                        <span>Employee Name&nbsp;:&nbsp;</span>
                                        <span className="text_caps"> {reportDetails?.staff_name} </span>
                                    </li>
                                    <li>
                                        <span>Employee Code&nbsp;:&nbsp;</span>
                                        <span className="text_caps"> {reportDetails?.staff_code} </span>
                                    </li>
                                    <li>
                                        <span>Designation&nbsp;&nbsp;:&nbsp;</span>
                                        <span className="text_caps">{reportDetails?.designation_title}</span>
                                    </li>
                                </ol>
                            </div>
                            <div className="transfer_form_section2_right">
                                <ol>
                                    <li>
                                        <span>Asset Transfer To&nbsp;:&nbsp;&nbsp;&nbsp;</span>
                                        {/* <span className="text_caps">{reportDetails?.current_staff_name}</span> */}
                                        <span className="text_caps">{reportDetails?.staff_name}</span>
                                    </li>
                                    <li>
                                        <span>Department&nbsp;&nbsp;&nbsp;:&nbsp;</span>
                                        <span className="text_caps">{reportDetails?.department_name}</span>
                                    </li>
                                    <li>
                                        <span>Handover Date&nbsp;&nbsp;:&nbsp;</span>
                                        <span className="text_caps">
                                            {CommonFunctions.ToJavaScriptDate(list[list.length - 1]?.received_on, 0)}
                                        </span>
                                    </li>
                                </ol>
                            </div>
                        </div>

                        {/* <!-- transfer form main section3 --> */}
                        <div className="transfer_form_section3">
                            <div className="transfer_acknowledgement">
                                <p className="text_hightlight">Dear, Sir/Madam,</p>
                                <p>We congratulate for joining the Narula Insfrastructure Pvt. Ltd. <span className="text_hightlight">(JIS Group)</span> </p>
                                <p>Please find the below as the asssets handed over to you</p>
                            </div>

                            <div className="asset_transfer_table">

                                <div className="t_head">
                                    <div className="t_row">
                                        <div className="t_data" style={{ width: "10%", textAlign: "center" }}>SL No.</div>
                                        <div className="t_data" style={{ width: "20%", textAlign: "center" }}>Date of received</div>
                                        <div className="t_data" style={{ width: "10%", textAlign: "center" }}>Brand</div>
                                        <div className="t_data" style={{ width: "27%", textAlign: "center" }}>Item sl no.</div>
                                        <div className="t_data" style={{ width: "18%", textAlign: "center" }}>Location</div>
                                        <div className="t_data" style={{ width: "15%", textAlign: "center" }}>Bucket</div>
                                    </div>
                                </div>
                                <div className="t_body">
                                    {
                                        list?.map((item, index) => (
                                            <div className="t_row">
                                                <div className="t_data" style={{ width: "10%", textAlign: "center" }}> {index + 1} </div>
                                                <div className="t_data" style={{ width: "20%", textAlign: "center" }}>
                                                    {
                                                        CommonFunctions.ToJavaScriptDate(item.received_on.split(" ")[0], 0)
                                                    }
                                                </div>
                                                <div className="t_data" style={{ width: "10%", textAlign: "center" }}>
                                                    {/* {item.brand} */}
                                                    {item.title}
                                                </div>
                                                <div className="t_data" style={{ width: "27%", textAlign: "center" }}>
                                                    {/* {item.item_sl_no} */}
                                                    {item.serial_no}
                                                </div>
                                                <div className="t_data" style={{ width: "18%", textAlign: "center" }}>
                                                    {locationList.find((ele) => ele.value === +item.location_to)?.text}
                                                    {/* {item.location_to} */}
                                                </div>
                                                <div className="t_data" style={{ width: "15%", textAlign: "center" }}>
                                                    {item.bucket}
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {/* <div className="t_row">
                                        <div className="t_data" style={{ width: "10%", textAlign: "center" }}>1</div>
                                        <div className="t_data" style={{ width: "20%", textAlign: "center" }}>01.08.2024</div>
                                        <div className="t_data" style={{ width: "10%", textAlign: "center" }}>Lenovo</div>
                                        <div className="t_data" style={{ width: "30%", textAlign: "center" }}>pg044hsm</div>
                                        <div className="t_data" style={{ width: "15%", textAlign: "center" }}>H.O</div>
                                        <div className="t_data" style={{ width: "15%", textAlign: "center" }}>Airtel</div>
                                    </div>
                                    <div className="t_row">
                                        <div className="t_data" style={{ width: "10%", textAlign: "center" }}>1</div>
                                        <div className="t_data" style={{ width: "20%", textAlign: "center" }}>01.08.2024</div>
                                        <div className="t_data" style={{ width: "10%", textAlign: "center" }}>Lenovo</div>
                                        <div className="t_data" style={{ width: "30%", textAlign: "center" }}>pg044hsm</div>
                                        <div className="t_data" style={{ width: "15%", textAlign: "center" }}>H.O</div>
                                        <div className="t_data" style={{ width: "15%", textAlign: "center" }}>Airtel</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="asset_transfer_signature">
                            <div className="signature_section">
                                <p>Authorized Signatory</p>
                                <p className="text_hightlight">(IT-Department of Narula Infrastructure Pvt. Ltd.)</p>
                            </div>
                            <div className="signature_section">
                                <p>Receiver's Signature</p>
                                <p className="text_hightlight">(Responsible Person For Asset)</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default AssetTransferReportPDF