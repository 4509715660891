const dropDownListAction = {
    ACT_BRANCH_LIST:'ACT_BRANCH_LIST',
    ACT_MANUFACTURER_LIST: 'ACT_MANUFACTURER_LIST',
    ACT_COUNTRY_STATE_LIST: 'ACT_COUNTRY_STATE_LIST',
    ACT_STATE_DISTRICT_LIST: 'ACT_STATE_DISTRICT_LIST',
    ACT_COUNTRY_LIST: 'ACT_COUNTRY_LIST',
    ACT_VENDOR_LIST: 'ACT_VENDOR_LIST',
    ACT_COMPLAINT_TYPE_DIRECT_LIST: 'ACT_COMPLAINT_TYPE_DIRECT_LIST',
    ACT_ASSET_LIST: 'ACT_ASSET_LIST',
    ACT_STATUS_LIST: 'ACT_STATUS_LIST',
    ACT_PREVIOUS_EMPLOYEE_LIST: 'ACT_PREVIOUS_EMPLOYEE_LIST',
    ACT_CURRENT_EMPLOYEE_LIST: 'ACT_CURRENT_EMPLOYEE_LIST',
    ACT_APPROVED_LIST: 'ACT_APPROVED_LIST',
    ACT_TRANSFERRED_LIST: 'ACT_TRANSFERRED_LIST',
    ACT_BRANCH_STAFF_LIST: 'ACT_BRANCH_STAFF_LIST',
    ACT_BRANCH_DEPARTMENT_LIST: 'ACT_BRANCH_DEPARTMENT_LIST',
    ACT_BRANCH_DESIGNATION_LIST: 'ACT_BRANCH_DESIGNATION_LIST',
    ACT_ROLE_LIST: 'ACT_ROLE_LIST',
    ACT_CATEGORY_LIST:'ACT_CATEGORY_LIST'
}
export default dropDownListAction;


