const apiIntegrationFunctions = {
    accessAPPApiKey: "0cae006d-e3b8-4e38-8221-5d95a068adb7",

    
    /****Base controller methods****/

    actionLogin: "controller/api-controller/actionLogin",
    loginActiveDeactive: "controller/api-controller/loginActiveDeactive",
    getDropDownList: "controller/api-controller/getDropDownList",
    filterDocumentCategoryList: "controller/api-controller/filterDocumentCategoryList",

    /***End of base controller methods***/

    /***Indrani methods***/
    getBranchDashBoardData: "controller/api-controller/getBranchDashBoardData",

    addBranch: "controller/api-controller/addBranch",
    filterBranchList: "controller/api-controller/filterBranchList",

    addBranchDocument: "controller/api-controller/addBranchDocument",
    filterBranchDocumentList: "controller/api-controller/filterBranchDocumentList",

    addCategory: "controller/api-controller/addCategory",
    filterCategoryList: "controller/api-controller/filterCategoryList",
    
    addDepartment: "controller/api-controller/addDepartment",
    updateDepartment: "controller/api-controller/updateDepartment",
    filterDepartmentList: "controller/api-controller/filterDepartmentList",

    addDesignation: "controller/api-controller/addDesignation",
    updateDesignation: "controller/api-controller/updateDesignation",
    filterDesignationList: "controller/api-controller/filterDesignationList",
    
    addEmployeeDetails: "controller/api-controller/addEmployeeDetails",
    updateEmployeeDetails: "controller/api-controller/updateEmployeeDetails",
    filterEmployeeDetailsList: "controller/api-controller/filterEmployeeDetailsList",
    deleteEmployeeDetails: "controller/api-controller/deleteEmployeeDetails",

    addStaffDepartment: "controller/api-controller/addStaffDepartment",
    updateStaffDepartment: "controller/api-controller/updateStaffDepartment",
    filterStaffDepartmentList: "controller/api-controller/filterStaffDepartmentList",

    addStaffDesignation: "controller/api-controller/addStaffDesignation",
    updateStaffDesignation: "controller/api-controller/updateStaffDesignation",
    filterStaffDesignationList: "controller/api-controller/filterStaffDesignationList",

    addStaffDocument: "controller/api-controller/addStaffDocument",
    filterStaffDocumentList: "controller/api-controller/filterStaffDocumentList",
   
    addEmployeeFromCSVFile: "controller/api-controller/addEmployeeFromCSVFile",

    addVendor: "controller/api-controller/addVendor",
    updateVendor: "controller/api-controller/updateVendor",
    filterVendorList: "controller/api-controller/filterVendorList",
    
    addVendorCSVFile: "controller/api-controller/addVendorCSVFile",

    addVendorDocument: "controller/api-controller/addVendorDocument",
    filterVendorDocumentList: "controller/api-controller/filterVendorDocumentList",

    addManufacture: "controller/api-controller/addManufacture",
    updateManufacture: "controller/api-controller/updateManufacture",
    filterManufactureList: "controller/api-controller/filterManufactureList",

    //addCustomerVehicle: "controller/api-controller/addCustomerVehicle",
    //updateCustomerVehicle: "controller/api-controller/updateCustomerVehicle",
    //filterCustomerVehicleList: "controller/api-controller/filterCustomerVehicleList",

    addAsset: "controller/api-controller/addAsset",
    updateAsset: "controller/api-controller/updateAsset",
    filterAssetList: "controller/api-controller/filterAssetList",

    addAssetDocument: "controller/api-controller/addAssetDocument",
    filterAssetDocumentList: "controller/api-controller/filterAssetDocumentList",

    addAssetImage: "controller/api-controller/addAssetImage",
    filterAssetImageList: "controller/api-controller/filterAssetImageList",

    addAssetWarranty: "controller/api-controller/addAssetWarranty",
    updateAssetWarranty: "controller/api-controller/updateAssetWarranty",
    filterAssetWarrantyList: "controller/api-controller/filterAssetWarrantyList",

    filterAssetHistoryList: "controller/api-controller/filterAssetHistoryList",

    addComplaintComm: "controller/api-controller/addComplaintComm",
    filterComplaintCommList: "controller/api-controller/filterComplaintCommList",

    addIssue: "controller/api-controller/addIssue",
    updateIssue: "controller/api-controller/updateIssue",
    filterIssueList: "controller/api-controller/filterIssueList",

    addTransfer: "controller/api-controller/addTransfer",
    filterTransferList: "controller/api-controller/filterTransferList",

    addMaintenance: "controller/api-controller/addMaintenance",
    updateMaintenance: "controller/api-controller/updateMaintenance ",
    filterMaintenanceList: "controller/api-controller/filterMaintenanceList",

    addAssetMaintenanceDocument: "controller/api-controller/addAssetMaintenanceDocument",
    filterAssetMaintenanceDocumentList: "controller/api-controller/filterAssetMaintenanceDocumentList",

    addComplaint: "controller/api-controller/addComplaint",
    filterComplaintList: "controller/api-controller/filterComplaintList",

    addAssetReturn: "controller/api-controller/addAssetReturn",
    filterAssetReturnList: "controller/api-controller/filterAssetReturnList",

    addAssetFromCSVFile: "controller/api-controller/addAssetFromCSVFile",


    filterAssetTransferReport: "controller/api-controller/filterAssetTransferReport",

    // filter new asset code.
    filterNewAssetCode: "controller/api-controller/filterNewAssetCode",

    // end point for asset destroy.
    assetDestroy: "controller/api-controller/assetDestroy",


    // end point for user role access.
    addUserAccess: "controller/api-controller/addUserAccess",
    updateUserAccess: "controller/api-controller/updateUserAccess",
    filterUserAccess: "controller/api-controller/filterUserAccess",



    /***End of Indrani methods***/

    
}

export default apiIntegrationFunctions

