const PageTitle = {
    HomePage: '',
    loginURL: '/general/login',
    testPage: '/app/testPage',
    testPage2: '/app/testPage2',
    dashBoardURL: '/app/dashboard',
    dashBoardPageCode: 'ASSET_DASHBOARD_CODE',
    branchURL: '/app/branch',
    branchPageCode: 'ASSET_BRANCH_CODE',

    categoryURL: '/app/category',
    categoryPageCode: 'ASSET_CATEGORY_CODE',

    assetURL: '/app/asset',
    assetPageCode: 'ASSET_ASSET_CODE',
    assetAddAssetURL: '/app/asset?item=1',
    addAssetPageCode: 'ASSET_ADDASSET_CODE',
    assetAllAssetsURL: '/app/asset?item=2',
    assetAllAssetPageCode:'ASSET_ALLASSET_CODE',
    assetEditAssetURL: '/app/asset?item=3',
    assetComplaintURL: '/app/asset?item=4',
    assetEditComplaintURL: '/app/asset?item=5',
    assetTransferURL: '/app/asset?item=6',
    assetTransferPageCode: "ASSET_TRANSFER_CODE",
    assetMaintenanceURL: '/app/asset?item=7',
    assetMaintenancePageCode: "ASSET_MAINTENANCE_CODE",
    assetReturnURL: '/app/asset?item=9',
    assetReturnPageCode: "ASSET_RETURN_CODE",
    assetIssueURL: '/app/asset?item=10',
    assetIssuePageCode: "ASSET_ISSUE_CODE",
    assetScrapPageCode: "ASSET_SCRAP_CODE",
    assetMultipleImportURL: '/app/asset?item=8',
    assetMultipleImportPageCode: 'ASSET_ASSET_MULTIPLE_IMPORT_CODE',

    vendorURL: '/app/vendor',
    vendorPageCode: 'ASSET_VENDOR_CODE', 
    vendorListURL: '/app/vendor?item=1',
    vendorListPageCode: 'ASSET_VENDORLIST_CODE',
    vendorMultipleImportURL: '/app/vendor?item=2',
    vendorMultipleImportPageCode: 'ASSET_VENDOR_MULTIPLE_IMPORT_CODE',

    employeeURL: '/app/employee',
    employeePageCode: 'ASSET_EMPLOYEE_CODE',
    employeeListURL: '/app/employee?item=1',
    employeeListPageCode: 'ASSET_EMPLOYEE_LIST_CODE',
    employeeDepartmentAdditionURL: '/app/employee?item=2',
    employeeDepartmentAdditionPageCode: 'ASSET_EMPLOYEE_DEPARTMENT_ADD_CODE',
    employeeDesignationURL: '/app/employee?item=3',
    employeeDesignationPageCode: 'ASSET_EMPLOYEE_DESIGNATION_ADD_CODE',
    employeeAdditionURL: '/app/employee?item=4',
    employeeAdditionPageCode: 'ASSET_EMPLOYEE_ADD_CODE',
    employeeMultipleImportURL: '/app/employee?item=5',
    employeeMultipleImportPageCode: 'ASSET_EMPLOYEE_MULTIPLE_IMPORT_CODE',

    manufacturerURL: '/app/manufacturer',
    manufacturerPageCode: 'ASSET_MANUFACTURER_CODE',

    reportURL: '/app/report',
    reportPageCode: 'ASSET_REPORT_CODE',
    assetTransferReportURL: '/app/report?item=1',
    assetTransferReportPageCode: 'ASSET_TRANSFER_REPORT_CODE',
    assetMaintenanceReportURL: '/app/report?item=2',
    assetMaintenanceReportPageCode: 'ASSET_MAINTENANCE_REPORT_CODE',
    userAccessURL: '/app/access',
    userAccessPageCode: 'ASSET_USER_ACCESS_CODE',
    settingsURL: '/app/settings',
    
}
export default PageTitle;


